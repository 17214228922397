import { Component, OnInit, Input } from '@angular/core';
import { AutenticacaoService } from 'src/app/service/autenticacao/autenticacao.service';
import { MenuItem } from 'primeng/api';
import { Router, NavigationStart } from '@angular/router';
import { enderecoServidorJava } from 'src/app/configuracao';

@Component({
  selector: 'app-cabecalho',
  templateUrl: './cabecalho.component.html',
  styleUrls: ['./cabecalho.component.css']
})
export class CabecalhoComponent implements OnInit {

  usuario: any = null;
  fazendoLogin = false;
  @Input() paginaInicial: boolean;
  public items: MenuItem[];
  public email = "";
  public senha = "";
  public enderecoJava = enderecoServidorJava;

  constructor(private autenticacaoService: AutenticacaoService,
    router: Router) {
    const th = this;
    autenticacaoService.bsAutenticacao.subscribe((usuario) => {
      th.usuario = usuario;
      if (usuario = null) {
        th.fazendoLogin = false;
      }
    });
    autenticacaoService.bsFazendoLogin.subscribe((fazendo) => {
      this.fazendoLogin = fazendo;
    });

    router.events.subscribe(
      (event) => {
        if (event instanceof NavigationStart) {
          this.fazendoLogin = false;
        }
      }
    )
  }

  ngOnInit() {
    this.items = [
      {
        label: 'Sobre',
        items: [
          { label: 'Sobre', url: 'https://conpedi.org.br/sobre/' },
          
        ]
      },      
      {
        label: 'Notícias',
        items: [
          { label: 'Conpedi', url : 'https://conpedi.org.br/noticias-ppgds/' },
          { label: 'PPGDs', url: 'https://conpedi.org.br/noticias/' },
          //  { label: 'Newsletter', routerLink: [''] },
        ]
      },
      {
        label: 'Eventos',
        items: [
          { label: 'CONPEDI', url: 'https://conpedi.org.br/eventos/' },
          { label: 'Parceiros', url: 'https://conpedi.org.br/eventos-parceiros/' },
        ]
      },
      {
        label: 'Editais',
        items: [
          { label: 'Conpedi - Publica direito', url: 'https://conpedi.org.br/editais/conpedi-publica-direito/' },
          { label: 'Parceiros - Publica direito', url: 'https://conpedi.org.br/editais/parceiros-publica-direito/' },
          { label: 'Diversos', url: 'https://conpedi.org.br/editais/diversos/' },
        ]
      },
      {
        label: 'Publicações',
        items: [
          { label: 'Periódicos', command: () => window.open("https://indexlaw.org ", "_blank") },
          { label: 'Artigos CONPEDI', command: () => { window.location.href = 'https://conpedi.org.br/publicacoes/' } },
          { label: 'Põsteres CONPEDI', url: 'https://conpedi.org.br/posters-conpedi/' },
          { label: 'Publicação de parceiros', url: 'https://conpedi.org.br/publicacoes-parceiros/' },
        ]
      },
      {
        label: 'Mídias',
        items: [
          { label: 'TV', url: 'https://conpedi.org.br/tv-conpedi/' },
          { label: 'Galerias', url: 'https://conpedi.org.br/galerias/' },
        ]
      },
      {
        label: 'Espaço do autor',
        items: [
          { label: 'Espaço do autor', url: 'https://conpedi.org.br/espaco-do-autor/' },          
        ]
      },      
      {
        label: 'Agenda',
        items: [
          { label: 'Agenda', url: 'https://conpedi.org.br/agenda/' },          
        ]
      },      
      {
        label: 'PPGDs',
        items: [
          { label: 'Notícias', url: 'https://conpedi.org.br/noticias-ppgds/' },
          { label: 'Editais', url: 'https://conpedi.org.br/editais/parceiros-publica-direito/' },
          { label: 'Eventos', url:'https://conpedi.org.br/eventos-parceiros/' },
          { label: 'Publicações', command: () => window.open('https://conpedi.org.br/publicacoes-parceiros/', '_') },
          { label: 'Mapa', url:'https://conpedi.org.br/#mapa' },
        ]
      },
    ];
  }

  entrar() {
    //this.fazendoLogin = !this.fazendoLogin;
    window.location.href = this.enderecoJava + '/login'
  }

  cancelaLogin() {
    this.fazendoLogin = false;
  }

  login() {
    this.autenticacaoService.entrarComEmailESenha(this.email, this.senha);
  }

  menuExtendido(): boolean {
    if (this.paginaInicial) {
      return true;
    }
    return this.fazendoLogin;
  }

  mostraMenu() {
    return this.usuario != null;
  }


  public sair(): void {
    this.autenticacaoService.logout();
  }
}
