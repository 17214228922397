import { Component } from '@angular/core';
import { enderecoServidorJava } from 'src/app/configuracao';
import { AutenticacaoService } from 'src/app/service/autenticacao/autenticacao.service';

@Component({
    selector: 'app-login-legado',
    templateUrl: './login-legado.component.html',
    styleUrls: ['./login-legado.component.css']
})
export class LoginLegadoComponent {

    public email: String;
    public senha: String;
    public enderecoJava = enderecoServidorJava;

    constructor(autenticacaoService: AutenticacaoService) {
        const th = this;
        autenticacaoService.bsAutenticacao.subscribe((usuario) => {
            if (!usuario) {
                return;
            }
            const info = autenticacaoService.verificaUsuarioSemAcesso(usuario);
            if (info) {
                this.email = info.email;
                this.senha = info.senha;
                document.getElementById('legado')['submit']();
            }
        });
    }

    ngOnInit() {
    }
}
