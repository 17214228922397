import { Component, OnInit, Input } from '@angular/core';
import { GraphqlService, Trabalho } from 'src/app/service/graphql/graphql.service';
import { ExcelService } from 'src/app/service/excel/excel.service';
import { enderecoServidorJava } from 'src/app/configuracao';

@Component({
  selector: 'app-evento-inscrito-consulta',
  templateUrl: './evento-inscrito-consulta.component.html',
  styleUrls: ['./evento-inscrito-consulta.component.css']
})
export class EventoInscritoConsultaComponent implements OnInit {

  @Input() idEvento: string;
  public registros = [];
  public cols: any[];

  constructor(private graphqlService: GraphqlService,
    private excel: ExcelService) { }

  ngOnInit() {
    this.cols = [
      { field: 'nome', header: 'Nome' },
      { field: 'email', header: 'Email' },
      { field: 'titulo', header: 'Título trabalho' },
    ];

    const th = this;
    this.graphqlService.obtemTrabalhosEventoArtigo(this.idEvento).subscribe(
      trabalhos => {
        var pessoas: Array<any> = [];
        for (var trabalho of trabalhos) {
          if (trabalho.tipoTrabalho == 'ARTIGO') {
            th.processaLista(pessoas, trabalho, trabalho.orientador);
            th.processaLista(pessoas, trabalho, trabalho.autor);
          }
        }
        th.registros = pessoas.sort((p1, p2) => {
          if (p1['nome'] == p2['nome']) {
            return 0
          } else if (p1['nome'] < p2['nome']) {
            return -1;
          } else {
            return 1;
          }
        });
      }
    )
  }

  processaLista(pessoas: any[], trabalho: Trabalho, lista: any) {
    for (var p of lista) {
      if (!p.inscrito) {
        pessoas.push({ idTabela: p.id + trabalho.titulo, nome: p.nome, email: p.email, titulo: trabalho.titulo, skPessoa: p.skPessoa });
      }
    }
  }

  exportarExcel() {
    this.excel.exportAsExcelFile([{
      1: "Autor/Orientador",
      2: "Email",
      3: "Trabalho",
    }, ...this.registros.map(r => {
      const data = {
        1: r.nome,
        2: r.email,
        3: r.titulo,
      }
      return data
    })], 'artigo_nao_inscrito', {
      skipHeader: true
    })
  }

  abrir(rec: any) {
    const url = enderecoServidorJava + '/admin/associado/' + rec['skPessoa'];
    window.open(url, '_');
  }

}
