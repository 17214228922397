import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/primeng';
import { enderecoServidorJava } from 'src/app/configuracao';

@Component({
  selector: 'app-menu-inicial',
  templateUrl: './menu-inicial.component.html',
  styleUrls: ['./menu-inicial.component.css']
})
export class MenuInicialComponent implements OnInit {

  items: MenuItem[];

  constructor() { }

  ngOnInit() {
    this.items = [
      {
        label: 'Sobre',
        items: [
          { label: 'Quem Somos', routerLink: ['/quem-somos'] },
          { label: 'Diretoria', routerLink: ['/diretoria'] },
          { label: 'Documentos', routerLink: ['/documentos'] },
          { label: 'Memória', routerLink: ['/memoria'] },
          { label: 'Fale conosco', routerLink: ['/fale-conosco'] },
        ]
      },
      {
        label: 'Notícias',
        items: [
          { label: 'Conpedi', routerLink: ['/detalhe-noticia-view/conpedi'] },
          { label: 'PPGDs', routerLink: ['/detalhe-noticia-view/ppgd'] },
          //  { label: 'Newsletter', routerLink: [''] },
        ]
      },
      {
        label: 'Editais',
        items: [
          { label: 'Conpedi - Publica direito', routerLink: [''] },
          { label: 'Parceiros - Publica direito', routerLink: [''] },
          { label: 'Diversos', routerLink: [''] },
        ]
      },
      {
        label: 'Publicações',
        items: [
          { label: 'Periódicos', routerLink: [''] },
          { label: 'Artigos CONPEDI', command: () => window.open(enderecoServidorJava +'/publicacoes', '_') },
          { label: 'Põsteres CONPEDI', routerLink: ['/publicacao-lista-evento/poster'] },
          { label: 'Publicação de parceiros', command: () => window.open(enderecoServidorJava + '/publicacoes', '_') },
        ]
      },
      {
        label: 'Mídias',
        items: [
          { label: 'TV', routerLink: ['/tvs-conpedi-view'] },
          { label: 'Revistas', command: () => window.open('https://issuu.com/conpedi', '_') },
          { label: 'Fotos', routerLink: ['/detalhe-galeria-view'] },
        ]
      },
      { label: 'Espaço do autor', routerLink: ['/espacos-autor-view'] },
      { label: 'Agenda', routerLink: ['/agenda'] },
      { label: 'Colunas', routerLink: [''] },
      {
        label: 'PPGDs',
        items: [
          { label: 'Notícias', routerLink: [''] },
          { label: 'Editais', routerLink: [''] },
          { label: 'Eventos', routerLink: [''] },
          { label: 'Publicações', routerLink: [''] },
          { label: 'Mapa', routerLink: [''] },
        ]
      },
    ];
  }

}
